import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Image = styled.img`
  width: 100%;
`

const ArtTemplate = ({ data }) => {
  const [available, setAvailable] = useState("Haetaan tietoa")
  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        "https://test.owrs.tk/inventories?art=" + data.strapiArt.strapiId
      )
      const inventory = await res.json()
      if (inventory.length) {
        const value = inventory[0].quantity > 0 ? "Saatavilla" : "Ei saatavilla"
        setAvailable(value)
        return
      }
      setAvailable("Ei saatavilla")
    }
    getData()
  }, [])
  return (
    <Layout>
      <SEO title="Art" />
      <h1>Art</h1>
      {data.strapiArt.name}
      <br />
      Saatavuus: {available}
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
      <Image src={data.strapiArt.image.publicURL} alt={data.strapiArt.name} />
    </Layout>
  )
}

export default ArtTemplate

export const query = graphql`
  query ArtTemplate($id: String!) {
    strapiArt(id: { eq: $id }) {
      strapiId
      name
      image {
        publicURL
      }
    }
  }
`
