import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import Header from "./header"

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0F0F0F;
    min-height: 100%;
    background-image: linear-gradient(to right, #A3A3A3, #00AAAA);
  }
  #___gatsby{
  }
  #gatsby-focus-wrapper{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
`
const FooterWrapper = styled.footer`
  padding: 1rem;
  font-size: 1rem;
  right: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background: black;
  a {
    color: inherit;
  }
`

const MainWrapper = styled.main`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
  flex: 1;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  const { title, menuLinks } = data.site.siteMetadata
  return (
    <>
      <GlobalStyle />
      <Header siteTitle={title} menuLinks={menuLinks} />
      <MainWrapper>{children}</MainWrapper>
      <FooterWrapper>
        © {new Date().getFullYear()},{` `}
      </FooterWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
